import React from "react";
import "./Syrve.css";
import { BsArrowDown } from "react-icons/bs";


const Syrve = ({ text, disabled = false, speed = 5, className = '' }) => {
  const animationDuration = `${speed}s`;

  return (
    <div id="SyrveSection">
      <div id="SyrveSectionInner">
      <div className="SectionNumberMainDiv">
        <h3 className="SectionNumber">
          სარესტორნო პროგრამა
        </h3>
        </div>
        <div id="Syrve">
          <h2>syrve</h2>
          <p>
            არის პროგრამული გადაწყვეტილება, რომელიც განკუთვნილია რესტორნების,
            კაფეების და სხვა ნებისმიერი სახეობის კვების ობიექტებისთვის
            <span>70000+ კმაყოფილი მომხმარებელი <br /> მსოფლიოს მასშტაბით</span>
          </p>
        </div>

        <a href="#pricingSectionMain">
      <div
            className={`shiny-text ${disabled ? 'disabled' : ''} ${className}`}
            style={{ animationDuration }}
          >
          გააგრძელე <BsArrowDown className='upDownAnimation' />
          
          </div>
          </a> 
      </div>
      
    </div>
  );
};

export default Syrve;

import React from 'react'
import { motion } from 'framer-motion';
import './Coming_Soon.scss'
import { useLocation } from 'react-router-dom';



const Coming_Soon = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);



  return (
      <div className="h-screen flex flex-col items-center justify-center bg-black text-white">
        <motion.h1 
          className="text-6xl font-bold mb-6"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
        This Page "{queryParams.get('service')}" is <br />
          Coming Soon
        </motion.h1>
  
        <motion.p
          className="text-lg mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          We're working on something awesome. Stay tuned!
        </motion.p>
  
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 1, duration: 1 }}
        >
          <input
            type="email"
            placeholder="Enter your email"
            className="p-3 rounded-l-lg text-black focus:outline-none"
          />
          <button className="btnComingSoon bg-blue-600 text-white p-3 rounded-r-lg hover:bg-blue-700">
            Notify Me
          </button>
        </motion.div>
  
        <motion.div
          className="mt-10"
          animate={{ rotate: 360 }}
          transition={{ duration: 10, repeat: Infinity, ease: "linear" }}
        >
          🚀
        </motion.div>
      </div>
  )
}

export default Coming_Soon
import React from "react";
import "./IntegrationSection.scss";
import computerImg from "../../assets/img/grid/3D Computer stand.png";
import glovo from "../../assets/img/grid/glovo.png";
import wolt from "../../assets/img/grid/wolt.png";
import bolt from "../../assets/img/grid/bolt.png";
import balance from "../../assets/img/grid/balance lgo.svg";
import fina from "../../assets/img/grid/fina_logo.svg";
import onesoft from "../../assets/img/grid/logohorisontal2noback.png";
import arealy from "../../assets/img/grid/arealy.png";

const IntegrationSection = () => {
  return (
    <section>
      <h3 className="SectionNumber">სარესტორნო პროგრამა</h3>
      <div id="integration">
        <h2>მორგებულია საქართველოსთვის</h2>

        <div id="integrationDiv">
          <div class="integrationFlex flexbox">
            <div className="grid-1">
              <div class="grid-item item1 intShemosavlebi">
                <h4>ინტეგრაცია შემოსავლების სამსახურთან</h4>
                <img src={computerImg} alt="computer 3D img" />
                <ul>
                  <li>შემოსავლის ზედნადები</li>
                  <li>გასავლის ზედნადები</li>
                  <li>შიდა გადაადგილება</li>
                </ul>
                <p className="forMore">
                  <a href="#integration">ვრცლად</a>
                </p>
              </div>

              <div class="grid-item item2 intAccount">
                <h4>ინტეგრირებულია საბუღალტრო პროგრამებთან</h4>
                <div className="flexbox">
                  <img src={balance} alt="balance" />
                  <img src={onesoft} alt="onesoft" />
                </div>
                <div className="">
                  <p className="forMore">
                    <a href="#integration">ვრცლად</a>
                  </p>
                </div>
              </div>
              <div class="grid-item item3 intFiscal">
                <h4>ინტეგრირებულია ფისკალურ რეგისტრატორებთან</h4>
              </div>
            </div>

            <div className="grid-2">
              <div class="grid-item item4 intBanks">
                <h4>ინტეგრირებულია თიბისი და საქართველოს ბანკებთან</h4>
                <img src={computerImg} alt="computer 3D img" />
                <ul>
                  <li>გადახდა</li>
                  <li>უკან დაბრუნება</li>
                  <li>ანგარიშწორება ქულებით</li>
                </ul>
              </div>
              <div class="grid-item item5 intDelivery">
                <h4>ინტეგრირებულია მიწოდების აგრეგატორებთან</h4>
                <div className="flexbox">
                  <img src={wolt} alt="wolt" />
                  <img src={glovo} alt="glovo" />
                  <img src={bolt} alt="bolt" />
                </div>
              </div>
              <div class="grid-item item6 intHostell">
                <div className="flex-item">
                  <h4>ინტეგრირებულია სასტუმროს მართვის სისტემებთან AREALY</h4>
                  <img src={arealy} alt="arealy" />
                </div>
                <p className="forMore">
                  <a href="#integration">ვრცლად</a>
                </p>
              </div>
              <div class="grid-item item7 intSms">
                <h4>ინტეგრირებულია SMS-გამგზავნ სისტემებთან</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntegrationSection;

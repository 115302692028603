import React from 'react'
import LOGO from '../../assets/img/grid/logo for dark mode.svg'
import './header.css'
import { LuArrowUpRight } from "react-icons/lu";
import Email  from '../Email/Email';
import { useState, useEffect } from 'react';
import {Outlet, Link} from 'react-router-dom'

const Header = () => {

  const [lastScrollY, setLastScrollY] = useState(0);
  const [hidden, setHidden] = useState(false);
  const [inactiveTime, setInactiveTime] = useState(null);
  let timeoutRef = null;
  const resetTimer = () => {
    if (timeoutRef) clearTimeout(timeoutRef);
    timeoutRef = setTimeout(() => {
      setInactiveTime(new Date().toLocaleTimeString()); // Set time after 5s of no scroll
    }, 5000);
  };

  useEffect(() => {
    window.addEventListener("scroll", resetTimer);
    resetTimer(); // Start timer initially
    return () => window.removeEventListener("scroll", resetTimer);
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY) {
       // Hide header when scrolling down
      setHidden(true);
    } else {
       // Hide header when scrolling down
      setHidden(false); // Show header when scrolling up
    }

    setLastScrollY(currentScrollY);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, lastScrollY]);

  return (
    <div id='header' className={`header ${hidden ? 'hidden' : ''}`}>
    <h1><Link to='/'><img src={LOGO} alt='Logo'/></Link></h1>

    <div id='listing'>
    <ul>
      <li>
        <Link to="/">სარესტორნო პროგრამა</Link>
      </li>
      <li>
        <Link to='Coming-Soon?service=IT მომსახურეობა'>IT მომსახურეობა</Link>
      </li>
      <li>
        <Link to='Coming-Soon?service=ვებ დეველოპმენტი'>ვებ დეველოპმენტი</Link>
      </li>
      <li>
        <Link to='Coming-Soon?service=მარკეტინგი'>მარკეტინგი</Link>
      </li>
      <li>
        <Link to='account?service=ბუღალტერია'>ბუღალტერია</Link>
      </li>
    </ul>
    </div>
    <Outlet />

        <Email />

    </div>
  )
}


export default Header
import React from "react";
import LOGO from "../../assets/img/grid/logo for dark mode.svg";
import VIDEO from "../../assets/video/663927f94f5472bc912b2130_669a774a3703b1fcd221603a_DNA Motion FullHD-transcode.mp4"
import "./Footer.scss";

const Footer = ({ text, disabled = false, speed = 2, className = '' }) => {
  const animationDuration = `${speed}s`;
  
  return (
    <div className="FooterSection" id="FooterSection">
    <video className='videoTag' autoPlay loop muted>
    <source src={VIDEO} type='video/mp4' />
</video>
      <div className="Footer footer-flexbox">
        <div className="footer-menu">
        <h3 className={`shiny-text-footer ${disabled ? 'disabled' : ''} ${className}`}
        style={{ animationDuration }}>MENU</h3>
          <ul>
            <li>
              <a href="#SyrveSection">სარესტორნო პროგრამა</a>
            </li>
            <li>
              <a href="#">IT მომსახურეობა</a>
            </li>
            <li>
              <a href="#">ვებ დეველოპმენტი</a>
            </li>
            <li>
              <a href="#">მარკეტინგი</a>
            </li>
            <li>
              <a href="#">ბუღალტერია</a>
            </li>
          </ul>
        </div>
        <div className="footer-company">

          <div className="footer-company-contact">
          <h3 className={`shiny-text-footer ${disabled ? 'disabled' : ''} ${className}`}
          style={{ animationDuration }}>Contact</h3>
            <a href="tel:+995596444994">+995 596 444 994</a>
            <a href="mailto:info@possolutions.ge">info@possolutions.ge</a>
          </div>
          <h3>
        </h3>
        </div>
        <div className="footer-socials">
        <h3 className={`shiny-text-footer ${disabled ? 'disabled' : ''} ${className}`}
        style={{ animationDuration }}>Socials</h3>
          <ul>
            <li>
              <a href="http://" target="_blank" rel="noopener noreferrer">
                Facebook
              </a>
            </li>
            <li>
              <a href="http://" target="_blank" rel="noopener noreferrer">
                Instagram
              </a>
            </li>
            <li>
              <a href="http://" target="_blank" rel="noopener noreferrer">
                Linkedin
              </a>
            </li>
            <li>
            <a href="http://" target="_blank" rel="noopener noreferrer">
              Tiktok
            </a>
          </li>
          </ul>
        </div>
      </div>
      <div className="footer-logo-flex">
      <h2>
      POS <br /> solutions
      </h2>
      </div>
      <div className="lineFooter">
      </div>
      <div className="rights">
       <p>©2025 <span>POSSOLUTIONS</span></p>
       <p>All Rights Reserved</p>
      </div>
    </div>
  );
};

export default Footer;

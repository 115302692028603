import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./Email.css";
import { LuArrowUpRight } from "react-icons/lu";
import { IoMdCloseCircleOutline } from "react-icons/io";


let body = document.getElementById('App');
let popupDiv = document.getElementById('popupDiv')
// body.addEventListener("click",  function () {
//   alert("wrapper");
// }, false);
// popupDiv.addEventListener("click", (ev) => {
//   alert("except");
//   ev.stopPropagation();
// }, false);


console.log(body)

const Email = () => {
  const [ popup, setPopup ] = useState(false);

  const togglePopup = () => {
     setPopup(!popup);
  };
  const form = useRef();

  const validateForm = () => {
    let x = document.forms["clientInfo"]["name"].value;
    return x;
  }


  const sendEmail = (e) => {
    console.log(validateForm())
    e.preventDefault();

    emailjs
      .sendForm("service_kjmru0f", "template_4cbzker", form.current, {
        publicKey: "8JZCxSbF_Ep6-wcxZ",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div className="">
    <p  onClick={togglePopup}>უფასო დემონსტრაცია <LuArrowUpRight id='upRight' />
    </p>
    
    {popup && (
        <div id="popup" className="popup">
        <div id="popupDiv" className="popupDiv">

          <form name="clientInfo" ref={form} onSubmit={sendEmail}>
          <button className="close-popup" onClick={togglePopup}>
                <IoMdCloseCircleOutline className="closeButton" />
        </button>
            <div>
              <label for="name">შეიყვანეთ თქვენი სახელი</label>
              <input type="text" name="name" />
            </div>
            <div>
              <label for="email">შეიყვანეთ თქვენი მეილი(ან ნომერი)</label>
              <input type="email" name="email" />
            </div>
            <div>
              <label for="number">შეიყვანეთ თქვენი ნომერი(ან მეილი)</label>
              <input type="tel" name="number" id="number" />
            </div>

            <label for="restaurant">რა ტიპის ობიექტია?</label>
            <div>
              <input type="checkbox" id="chain" name="chain" value="chain" />
              <label for="chain">ქსელური</label>
            </div>
            <div>
              <input
                type="checkbox"
                id="tableservice"
                name="tableservice"
                value="რესტორანი"
              />
              <label for="tableservice">რესტორანი</label>
            </div>
            <div>
              <input
                type="checkbox"
                id="fastFood"
                name="fastFood"
                value="სწრაფი კვება"
              />
              <label for="fastFood">სწრაფი კვება</label>
            </div>
            <div>
              <input
                type="checkbox"
                id="caffee"
                name="caffee"
                value="კაფე/ბარი"
              />
              <label for="caffee">კაფე/ბარი</label>
            </div>
            <input type="submit" value="მოითხოვე დემო" />
          </form>
        </div>
        </div>
      )}
    </div>
  );
};

export default Email;

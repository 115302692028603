import "./App.css";
import Header from "./components/Header/Header";
import Banner from "./components/Banner/Banner";
import Syrve from "./components/Syrve/Syrve";
import Features from "./components/Features/Features";
import IntegrationSection from "./components/IntegrationSection/IntegrationSection";
import Footer from "./components/Footer/Footer";
import ComingSoon from "./components/Coming-Soon/Coming_Soon";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Account from "./components/Account/Account";

function Home() {
  return (
    <div className="">
    <div className="body">
      <Banner />
      <Syrve />
      <Features />
      <IntegrationSection />
      <footer className="App-Footer">
        <Footer />
      </footer>
    </div>
    <div className="coming-soon-tag">
        <ComingSoon />
        </div>

    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <div id="App" className="App">
        <header className="App-header">
          <Header />
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/account" element={<Account />}  />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;

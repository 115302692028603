import React, { useState } from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { GoDotFill, GoDot } from "react-icons/go";
import { BsArrowDown } from "react-icons/bs";

import "./Features.css";

const data = [
  {
    id: 0,
    category: "გაყიდვები და სტუმრების მომსახურება",
    items: [
      {
        title: "ონლაინ-სალარო, შეკვეთების მიღება და დამუშავება",
        plans: { basic: true, pro: true, enterprise: true },
      },
      {
        title: "ჩეკების ბეჭდვა სამზარეულოში",
        plans: { basic: true, pro: true, enterprise: true },
      },
      {
        title: "შტრიხკოდებით მუშაობა",
        plans: { basic: true, pro: true, enterprise: true },
      },
      {
        title: "გადახდის მეთოდები",
        plans: { basic: true, pro: true, enterprise: true },
      },
      {
        title: "მომხმარებლის ეკრანი სწრაფი კვების სალაროებისთვის",
        plans: { basic: true, pro: true, enterprise: true },
      },
      {
        title: "სტუმრების მომსახურება მაგიდებთან, რეზერვაცია და ბანკეტები",
        plans: { basic: true, pro: true, enterprise: true },
      },
      {
        title: "ელექტრონული QR-მენიუ",
        plans: { basic: true, pro: true, enterprise: true },
      },
      {
        title: "კერძების კონსტრუქტორი, კომბო-კომპლექტები",
        plans: { basic: true, pro: true, enterprise: true },
      },
      {
        title: " შეთავაზებების შეტყობინებები თანამშრომლებისთვის",
        plans: { basic: true, pro: true, enterprise: true },
      },
      {
        title: "სამზარეულოს ეკრანი და რიგის ნომრები",
        plans: { basic: false, pro: true, enterprise: true },
      },
      {
        title: "მომზადებული კერძების აღრიცხვა და კონტროლი",
        plans: { basic: true, pro: true, enterprise: true },
      },
      {
        title: "ტარიფირებული სერვისების გაყიდვა (ბილიარდი, კარაოკე და ა.შ.)",
        plans: { basic: true, pro: true, enterprise: true },
      },
    ],
  },
  {
    id: 1,
    category: "ბიზნესის მართვა",
    items: [
      {
        title: "საწყობის, გაყიდვების და ჩამოწერების მართვა Live რეჟიმში",
        plans: { basic: true, pro: true, enterprise: true },
      },
      {
        title:
          "საბაზისო (ABC/XYZ, პერიოდის გაყიდები და ა.შ.) რეპორტები Live რეჟიმში",
        plans: { basic: false, pro: true, enterprise: true },
      },
      {
        title: "რეპორტების აწყობის მარტივი ფუნციონალი",
        plans: { basic: false, pro: true, enterprise: true },
      },
      {
        title: "საბაზო ოპერაციების მართვა სმარტფონიდან",
        plans: { basic: false, pro: true, enterprise: true },
      },
      {
        title: "გაყიდვების ავტომატური პროგნოზირება და რესურსების დაგეგმვა",
        plans: { basic: false, pro: true, enterprise: true },
      },
      {
        title: "მომწოდებლებთან შეკვეთების ავტომატური ფორმირება",
        plans: { basic: false, pro: true, enterprise: true },
      },
      {
        title: "სახიფათო ოპერაციებზე შეტყობინებები",
        plans: { basic: false, pro: true, enterprise: true },
      },
      {
        title: "ინდივიდუალურად მორგებული აღრიცხვა და ანალიტიკა",
        plans: { basic: false, pro: true, enterprise: true },
      },
      {
        title: "პერსონალის მართვის მარტივი ფუნქციონალი",
        plans: { basic: false, pro: true, enterprise: true },
      },
      {
        title: "სახელფასო და საბონუსე სისტემის მართვა პროგრამულად",
        plans: { basic: false, pro: true, enterprise: true },
      },
    ],
  },
  {
    id: 2,
    category: "საკუთარი საკურიერო მომსახურება",
    items: [
      {
        title: "შეკვეთების ცენტრალიზებული მიღება და განაწილება (ქოლ-ცენტრი)",
        plans: { basic: false, pro: false, enterprise: true },
      },
      {
        title:
          "მიწოდების ეტაპების და კურიერების გადაადგილების კონტროლი Live რეჟიმში (Google Maps, Yandex Maps)",
        plans: { basic: false, pro: false, enterprise: true },
      },
      {
        title: "მომხმარებლის შეკვეთის ისტორიის მართვა",
        plans: { basic: false, pro: false, enterprise: true },
      },
      {
        title: "უბნების მიხედვით შეკვეთების სიხშირის ნახვა",
        plans: { basic: false, pro: false, enterprise: true },
      },
      {
        title:
          "Live რეჟიმში Google/Yandex Map-ის საშუალებით, მიწოდებული შეკვეთების სტატისტიკის ნახვა უბნების მიხედვით",
        plans: { basic: false, pro: false, enterprise: true },
      },
    ],
  },
  {
    id: 3,
    category: "ქსელური ობიექტების მართვა",
    items: [
      {
        title:
          "ქსელის მენეჯმენტის ძირითადი ელემენტები და კონსოლიდირებული ანგარიშგება",
        plans: { basic: false, pro: true, enterprise: true },
      },
      {
        title:
          "ცენტრალიზებული აღრიცხვა და ქსელური მართვის მოწინავე ინსტრუმენტები",
        plans: { basic: false, pro: true, enterprise: true },
      },
      {
        title:
          "მთავარი საწყობის, წარმოების და ქსელური რესტორნების მართვა პროგრამულად (ცენტრალიზებული შეკვეთები, წარმოებული პროდუქტების გაცემა და ა.შ.)",
        plans: { basic: false, pro: true, enterprise: true },
      },
      {
        title: "ფრენჩაიზის მართვა",
        plans: { basic: false, pro: true, enterprise: true },
      },
      {
        title: "გაერთიანებული ლოიალობის პროგრამები",
        plans: { basic: false, pro: true, enterprise: true },
      },
    ],
  },
  {
    id: 4,
    category: "დამატებითი აპლიკაციები",
    items: [
      {
        title: "მობილური აპლიკაცია რეპორტებისთვის",
        plans: { basic: true, pro: true, enterprise: true },
      },
      {
        title: "მობილური აპლიკაცია მიმტანებისთვის",
        plans: { basic: false, pro: true, enterprise: true },
      },
      {
        title: "თვით მომსახურების სტენდი (კიოსკი)",
        plans: { basic: false, pro: true, enterprise: true },
      },
      {
        title: "მობილური აპლიკაცია კურიერებისთვის",
        plans: { basic: false, pro: false, enterprise: true },
      },
    ],
  },
  // {
  //   id: 5,
  //   category: "პროგრამა ინტეგრირებულია",
  //   items: [
  //     {
  //       title: "შემოსალების სამსახურის ელ. პორტალთან (Rs.ge)",
  //       plans: { basic: false, pro: true, enterprise: true },
  //     },
  //     {
  //       title: "აგრეგატორებთან (Glovo, Wolt, Bolt)",
  //       plans: { basic: false, pro: true, enterprise: true },
  //     },
  //     {
  //       title: "საბანკო ტერმინალებთან (BOG, TBC)",
  //       plans: { basic: false, pro: true, enterprise: true },
  //     },
  //     {
  //       title: "ფისკალურთან",
  //       plans: { basic: false, pro: true, enterprise: true },
  //     },
  //     {
  //       title: "სმს შეტყობინებებთან (Sender.ge)",
  //       plans: { basic: false, pro: true, enterprise: true },
  //     },
  //     {
  //       title: "სასწორთან",
  //       plans: { basic: false, pro: true, enterprise: true },
  //     },
  //     {
  //       title: "Face ID მოწყობილობასთან",
  //       plans: { basic: false, pro: true, enterprise: true },
  //     },
  //     {
  //       title: "ლოიალურობის სისტემებთან",
  //       plans: { basic: false, pro: true, enterprise: true },
  //     },

  //   ],
  // }
];

const Features = ({ text, disabled = false, speed = 5, className = "" }) => {
  const animationDuration = `${speed}s`;

  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (id) => {
    setOpenSection((prevId) => (prevId === id ? null : id));
  };

  return (
    <div id="pricingSectionMain">
      <h3 className="SectionNumber">სარესტორნო პროგრამა</h3>
      <div className="pricing-section">
        <h2>
          <span>syrve</span>-ს შესაძლებლობები
        </h2>
        <div className="featureHeader">
          <span id="firstSpan"></span>
          <span>BASIC</span>
          <span>PRO</span>
          <span>ENTERPRISE</span>
          <span></span>
        </div>
        {data.map((section) => (
          <div
            key={section.id}
            className={`pricing-row ${
              openSection === section.id ? "active" : ""
            }`}
          >
            <div
              className="pricing-title"
              onClick={() => toggleSection(section.id)}
            >
              <span>{section.category}</span>
              <span className={`arrow ${openSection === section.id ? "" : ""}`}>
                ▼
              </span>
            </div>
            <div
              className="expandable-content"
              style={{
                maxHeight: openSection === section.id ? "500px" : "0",
                overflowX: "none",
                overflowY: "scroll",
                width: "99.6%",
                transition: "max-height 0.8s ease-in-out",
              }}
            >
              <ul>
                {section.items.map((item, index) => (
                  <li key={index} className="pricing-list-item">
                    <div className="list-title">{item.title}</div>
                    <div className="plan-icons">
                      <span className="plan-icon">
                        {item.plans.basic ? (
                          <GoDotFill className="icon basic" />
                        ) : (
                          <GoDot className="icon" />
                        )}
                      </span>
                      <span className="plan-icon">
                        {item.plans.pro ? (
                          <GoDotFill className="icon pro" />
                        ) : (
                          <GoDot className="icon" />
                        )}
                      </span>
                      <span className="plan-icon enterpriseIcon">
                        {item.plans.enterprise ? (
                          <GoDotFill className="icon enterprise" />
                        ) : (
                          <GoDot className="icon" />
                        )}
                      </span>
                      <span className="reserve"></span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div
              style={{
                height: openSection === section.id ? "2rem" : "0",
                overflow: "auto",
                transition: "height 0.5s ease-in-out",
              }}
              className={`beforeActivate`}
            ></div>
          </div>
        ))}
      </div>
      <a href="#integration">
        <div
          className={`shiny-text ${disabled ? "disabled" : ""} ${className}`}
          style={{ animationDuration }}
        >
          გააგრძელე <BsArrowDown className="upDownAnimation" />
        </div>
      </a>
    </div>
  );
};

export default Features;
